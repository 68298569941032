import React from "react";

import Phone from "./Header/Phone";
import Mailicon from "./Header/Mailicon";
import Instagramicon from "./Header/Instagramicon";

import Whatappicon from "./Header/Whatappicon";
import Youtube from "./Header/Youtube";
import Facebookicon from "./Header/Facebookicon";
import Locatoricon from "./Header/Locatoricon";
// import { FaPlayCircle } from "react-icons/fa";
// import { FaFacebookF } from "react-icons/fa";
import "./Header.css";

function Header() {
  return (
    <div className="headerGridContainer">
      <div className="gridItemPhone">
        <Phone />
      </div>
      <div className="gridItemMail">
        <Mailicon />
      </div>
      <div className="productIcon">
        <div className="product">
          <Facebookicon />
        </div>
        <div className="product">
          <Whatappicon />
        </div>
        <div className="product">
          <Instagramicon />
        </div>
        <div className="product">
          <Youtube />
        </div>

        <div className="product">
          <Locatoricon />
        </div>
      </div>
      {/* </div> */}
      {/* <FaInstagram style={{ color: "yellow", marginLeft: "600px" }} /> */}
      {/* <FaWhatsapp style={{ color: "yellow", marginLeft: "10px" }} /> */}
      {/* <FaPlayCircle style={{ color: "yellow", marginLeft: "10px" }} /> */}
      {/* <FaFacebookF style={{ color: "yellow", marginLeft: "10px" }} /> */}
    </div>
  );
}
export default Header;
