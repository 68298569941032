import React from "react";
import "./Testimonial.css";
import img0 from "./images/laxmi4.jpg";
import img1 from "./images/LaxmiT1.jpeg";
import img2 from "./images/LaxmiT2.jpeg";
import img3 from "./images/LaxmiT3.jpeg";
import img4 from "./images/LaxmiT4.jpeg";

// import Blinking from "./Blinking";
function Testimonial() {
  return (
    <div>
      <div className="imgContainer1">
        <img src={img0} alt="laxmi" />
      </div>
      {/* <Blinking /> */}
      <div className="imgTextContainer">
        <h3>NATIONAL TESTIMONIALS</h3>
      </div>
      {/* ////////////////////////////// */}
      <div className="grid-Container" id="flex-Container">
        <img src={img1} alt="laxmi" />

        <div className="text">
          <h4>MADHAVI CHIPPADA</h4>
          <h5>Oil Painting</h5>
          <div className="ptext">
            <p>
              Laxmi, Art is like Meditation to me and you are the right teacher
              who taught me the techniques. You are one fine artist who is
              selfless and is so committed to teach your students the right
              techniques. I am always indebted to you “my Guru”🙏.
            </p>
          </div>
        </div>
      </div>
      <div className="grid-Container" id="flex-Container">
        <img src={img2} alt="laxmi" />

        <div className="text">
          <h4>RUPA PRAKASH</h4>
          <h5>Acrylic Paint</h5>
          <div className="ptext">
            <p>
              Laxmi is a wonderful teacher ! Her enthusiasm, love of expression
              encourages all in the class, regardless of the level...Her
              positive approach creates a great vibe in the class which helps us
              learn & improve significantly Am glad to have found a dear teacher
              & friend Laxmi ❤️🙏!!
            </p>
          </div>
        </div>
      </div>

      {/* ///////////////////////////////////////////// */}

      <div className="grid-Container" id="flex-Container">
        <img src={img3} alt="laxmi" />

        <div className="text">
          <h4>SWAPNA JOHNNY</h4>
          <h5>Oil Painting</h5>
          <div className="ptext">
            <p>
              A marvellous artist and furthermore an inspiring teacher! She has
              always inculcated a positive and calming vibe in her art classes,
              helping everyone grow and learn at their own pace. I have gotten
              to learn various techniques from her which has helped me refine
              and improve.
            </p>
          </div>
        </div>
      </div>
      <div className="grid-Container" id="flex-Container">
        <img src={img4} alt="laxmi" />

        <div className="text">
          <h4>Dr Uma Devi Sunkari (MD DGO gynaecologist)</h4>

          <h5>Oil Painting</h5>
          <div className="ptext">
            <p>
              At 70 years old, I am pursuing my passion for art and taking
              classes with Laxmi. Age doesn't hinder my desire to learn and
              create art, and I am thrilled to be able to accomplish my lifelong
              passion.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Testimonial;
