import React from "react";
import { FaPlay } from "react-icons/fa";
import "./Youtube.css";
function Youtube() {
  return (
    <div className="YoutubeIcon">
      <a
        href="https://www.youtube.com/@laxmiagarwal-tk6dr/featured"
        aria-label="Go to Youtube channel of Soul Laxmi Arts Studio"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaPlay />
      </a>
    </div>
  );
}
export default Youtube;
// https://www.youtube.com/@laxmiagarwal-tk6dr/shorts
// https://youtube.com/shorts/gaPATceVd_4?feature=share
