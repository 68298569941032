import React from "react";
import "./Book.css";
// import Whatappicon from "./Header/Whatappicon";
function Book() {
  return (
    <div className="book">
      <p style={{ fontWeight: 700 }}>Quick Enquiry</p>
    </div>
  );
}
export default Book;
