import React from "react";
import Typed from "react-typed";
import "./Blinking.css";
// import { Diamond } from "react-awesome-shapes";
// { fame = [{ fame }], pallery = [{ pallery }] }
// fame = [{ fame }],
function Blinking({ text = [] }) {
  // console.log(text);
  return (
    <>
      <div className="blinkerContainer">
        <div className="blinkerContainerText">
          <h2> Announcement</h2>
        </div>

        <div className="type1">
          <Typed
            strings={text}
            //
            //
            // strings={[
            //   "I am a full stack developer,and i love making websites ",
            //   "I love coding",
            //   "I am a techy and love playing Badminton",
            // ]}
            // strings={[{ fame: { fame } }]}
            typeSpeed={100}
            backSpeed={5}
            loop
          />
        </div>
      </div>
    </>
  );
}
export default Blinking;
// originally typespeed 150
// back speed 100
