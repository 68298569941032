import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "./Whatappicon.css";
function Whatappicon() {
  return (
    <div className="whatappIcon">
      <a
        href="https://wa.me/919832331428"
        aria-label="Whatsapp to Soul Laxmi Arts Studio"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp />
      </a>
    </div>
  );
}
export default Whatappicon;
