import React from "react";
import { FaPhoneVolume } from "react-icons/fa";
import "./Phone.css";
function Phone() {
  return (
    <>
      <div className="phoneContainer">
        <a
          href="tel:+918637800177"
          aria-label="Make a Phone call to Soul Laxmi Arts Studio"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaPhoneVolume />
          8637800177
        </a>
      </div>
    </>
  );
}
export default Phone;
