import React from "react";
import "./Locatoricon.css";
import { FaMapMarkerAlt } from "react-icons/fa";
function Locatoricon() {
  return (
    <div className="locatorIcon">
      <a
        href="https://www.google.com/maps/place/Soul+Laxmi+Arts+Studio/@17.4416233,78.3580996,17z/data=!3m1!4b1!4m6!"
        aria-label="Go to Location map of Soul Laxmi Arts Studio"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaMapMarkerAlt />
      </a>
    </div>
  );
}
export default Locatoricon;
