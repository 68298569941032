import React from "react";
import "./Footer.css";
import Phone from "./Header/Phone";
import Mailicon from "./Header/Mailicon";
import Instagramicon from "./Header/Instagramicon";
import { Link } from "react-router-dom";
import Whatappicon from "./Header/Whatappicon";
import Youtube from "./Header/Youtube";
import Facebookicon from "./Header/Facebookicon";
import Locatoricon from "./Header/Locatoricon";
// import img1 from "./images/Laxmivideopic.jpeg";
function Footer() {
  return (
    <>
      <div className="footerContainer">
        <div className="footeradressContainer">
          <h2>Soul Laxmi Arts Studio</h2>
          <p>
            SOUL LAXMI ARTS STUDIO no.2/37, <br />
            nr.city pearl,Vinayak Nagar,
            <br />
            Indira Nagar Gachibowli,Hyderabad,
            <br />
            Telangana-500032.
          </p>

          <p>
            <div className="locator">
              <Locatoricon />
            </div>
            Monday to Sunday:11 am-6pm
          </p>
        </div>

        {/* <div className="footerIconsContainer">
          <h4>Connect us</h4>
          <p>
            <Phone /> <Mailicon />
            <div className="footerIconsContainerIcons">
              <Whatappicon />
              <Instagramicon />
              <Youtube />
              <Facebookicon />
            </div>
          </p>

          
        </div> */}

        <div className="footerQuickLinks">
          <h2>Quick Links</h2>
          <div className="footerQuickLinkstext">
            <Link to={"./"}>
              <p className="home">Home</p>
            </Link>
            <Link to={"./Gallery1"}>
              <p className="gallery">Gallery</p>
            </Link>
            <Link to={"./Testimonial"}>
              <p className="testimonial"> Testimonial</p>
            </Link>

            <a href="https://wa.me/919832331428">
              <p className="bookAppointment">Book Appointment</p>
            </a>
            <a href="mailto:soullaxmiartsstudio@gmail.com" target="rel_blank">
              <p className="contact">Contact Us</p>
            </a>
            <p>Exhibitions</p>
          </div>
        </div>
        <div className="footerCourses">
          <h2>Courses</h2>
          <div className="footercoursestext1">
            <p>Pencil Sketching</p>
            <p>Charcoal</p> <p>Acrylic On Canvas</p>
            <p>Water Colors</p> <p>Oil On Canvas</p>
            <p>Hyperrealistic Art</p>
          </div>
        </div>
        <div className="footercoursestext2">
          <h2>Courses</h2>
          <p>Realistic Color </p> <p>Pencil Pastels</p>
          <p>Poster Color</p>
          <p>Oil Pastels</p>
          <p>Soft Pastels</p>
          <p>Oil painting</p>
        </div>
        <div className="footerIconsContainer">
          <h2>Connect us</h2>
          <p>
            <Phone /> <Mailicon />
          </p>
          <div className="footerIconsContainerIcons">
            <p>
              <Whatappicon />
              <Instagramicon />
              <Youtube />
              <Facebookicon />
            </p>
          </div>

          {/* <Locatoricon /> */}
        </div>
      </div>
      <div className="enderFooter">
        <div className="ender">
          <p> @2023 All rights reserved.</p>

          <div className="power">
            <p>Powered by Intonovus</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
