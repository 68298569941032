import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./Header";
import Navbar from "./Navbar";
import Book from "./Book";
import Gallery1 from "./Gallery1";
import Home from "./Home";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
import Courses from "./Courses";

// import { useState } from "react";
// import { FaBars } from "react-icons/fa";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Navbar />
        <a href="https://wa.me/919832331428" target="_blank">
          <Book />
        </a>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Gallery1" element={<Gallery1 />} />
          <Route exact path="/Testimonial" element={<Testimonial />} />
          <Route exact path="/Courses" element={<Courses />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
//
export default App;
// STREAM NAME
// https://soullaxmi-artsstudio.web.app/
// STREAM URL
// https://soullaxmi-artsstudio.web.app/
// STREAM ID
// 5239819774
// MEASUREMENT ID
// G-RBBC69BC12