//vist: https://www.npmjs.com/package/react-slick
//https://www.npmjs.com/package/react-slick
//package react slick from npm js
//1st step->   npm install react-slick --save
//2nd step->   npm install slick-carousel

// Import css files
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
//image carousel size 768*1024px
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./karousel.css";
// import "./carouselSlick.scss";
// import "./carouselslickTheme.scss";
// import img22 from "./images/LaxmiE22.jpeg";
import img23 from "./images/LaxmiE23.jpeg";
import img24 from "./images/LaxmiE24.jpeg";
// import img25 from "./images/LaxmiE25.jpeg";
// import img28 from "./images/LaxmiE28.jpeg";
import img27 from "./images/LaxmiE27.jpeg";
// import img30 from "./images/LaxmiE30.jpeg";
import img31 from "./images/LaxmiE31.jpeg";
import img29 from "./images/LaxmiE29.jpeg";
import img32 from "./images/LaxmiE32ace.jpeg";
import img33 from "./images/LaxmiE33ace.jpeg";
import img34 from "./images/LaxmiE34ace.jpeg";
// import img36 from "./images/LaxmiE36.jpeg";
import img37 from "./images/LaxmiE37.jpeg";
import img38 from "./images/LaxmiE38.jpeg";
import img39 from "./images/LaxmiE39.jpeg";

//import img35 from "./images/LaxmiE35ace.jpeg";
// import img29 from "./images/LaxmiE29.jpeg";
// import img24 from "./images/LaxmiE24.jpeg";

function Karousel() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="mainContainer">
      <h2> Exhibitions </h2>
      <Slider {...settings}>
        {/* //1// */}
        <div className="containerItem ">
          <h3>Index, Mumbai</h3>
          <img src={img37} alt="laxmi" />
        </div>

        <div className="containerItem ">
          {/* 2 */}
          <h3>ACETECH, Hyderabad</h3>
          <img src={img34} alt="laxmi" />
        </div>

        <div className="containerItem ">
          {/* 3 */}
          <h3>Nehru Art Gallery, Mumbai</h3>
          <img src={img29} alt="laxmi" />
        </div>
        <div className="containerItem ">
          {/* 4 */}
          <h3>Nehru Art Gallery, Mumbai</h3>
          <img src={img23} alt="laxmi" />
        </div>
        <div className="containerItem ">
          {/* 5 */}
          <h3>ACETECH, Hyderabad</h3>
          <img src={img32} alt="laxmi" />
        </div>
        <div className="containerItem ">
          {/* 6 */}
          <h3>Nehru Art Gallery, Mumbai</h3>
          <img src={img24} alt="laxmi" />
        </div>
        <div className="containerItem ">
          {/* 7 */}
          <h3>Nehru Art Gallery, Mumbai</h3>
          <img src={img27} alt="laxmi" />
        </div>
        <div className="containerItem ">
          {/* 8 */}
          <h3>ACETECH, Hyderabad</h3>
          <img src={img33} alt="laxmi" />
        </div>
        <div className="containerItem ">
          {/* 9 */}
          <h3>Nehru Art Gallery, Mumbai</h3>
          <img src={img31} alt="laxmi" />
        </div>
        <div className="containerItem ">
          {/* 10 */}
          <h3>ACETECH, Hyderabad</h3>
          <img src={img34} alt="laxmi" />
        </div>
        <div className="containerItem ">
          {/* 11 */}
          <h3>Index, Mumbai</h3>
          <img src={img38} alt="laxmi" />
          {/* The size of studio img is different so placed at last img36 */}
        </div>
        <div className="containerItem ">
          {/* 12 */}
          <h3>Index,Mumbai</h3>
          <img src={img39} />
        </div>
      </Slider>
    </div>
  );
}

export default Karousel;
