import React from "react";
// import imgG6 from "./images/Laxmi2079G6.jpg";
import img0 from "./images/Laxmiabstract.jpeg";
import img1 from "./images/Laxmiposter.jpeg";
import img2 from "./images/Laxmioilpastel.jpeg";
import img3 from "./images/Laxmiwatercolor.jpeg";
import img4 from "./images/Laxmipencil.jpeg";
import img5 from "./images/Laxmiadultpencil.jpeg";
import img6 from "./images/Laxmioilpainting1.jpeg";
import img7 from "./images/Laxmioilpainting2.jpeg";
import img8 from "./images/Laxmioilpainting3.jpeg";
import img9 from "./images/Laxmivideopic.jpeg";
import { FaRegCheckCircle } from "react-icons/fa";
import "./Courses.css";
function Courses() {
  return (
    <>
      <h1>Art Courses</h1>
      <div className="grid-container">
        <div className="grid-Item1">
          <div className="grid1">
            <p>
              Soul Laxmi Arts Studio is a renowned institution that offers art
              classes for a diverse range of students, including children aged 4
              years to senior citizens aged 70 and above. The studio aims to
              develop art skills and conceptual thinking among budding artists,
              and provides a variety of classes, summer camps, and workshops on
              various art forms. The experienced faculty and the creative
              atmosphere of Soul Laxmi Arts Studio make it a wonderful place for
              all participants to explore different materials and mediums, which
              helps them refine their artistic abilities. Overall, Soul Laxmi
              Arts Studio is an excellent destination for anyone looking to
              nurture their creativity and artistic talent, regardless of their
              age or background.
            </p>
          </div>
        </div>
        <div className="grid-Item2">
          <img src={img0} alt="Laxmi" />
        </div>
        <div className="containerfx" id="containerb">
          <div className="grid-Item3">
            <div className="text">
              <FaRegCheckCircle
                style={{ color: "red", margin: "0", padding: "0" }}
              />
              <p>Pencil Drawing and Sketching</p>
            </div>
            <div className="text">
              <FaRegCheckCircle style={{ color: "red" }} />
              <p>Pencil colours</p>
            </div>
            <div className="text">
              <FaRegCheckCircle style={{ color: "red" }} />
              <p>Soft pastels</p>
            </div>
            <div className="text">
              <FaRegCheckCircle style={{ color: "red" }} />
              <p>Oil pastels</p>
            </div>
          </div>
          <div className="grid-Item4">
            <div className="text">
              <FaRegCheckCircle style={{ color: "red" }} />
              <p>Acrylic colours</p>
            </div>
            <div className="text">
              <FaRegCheckCircle style={{ color: "red" }} />
              <p>Water colours</p>
            </div>
            <div className="text">
              <FaRegCheckCircle style={{ color: "red" }} />
              <p>Clay modelling</p>
            </div>
            <div className="text">
              <FaRegCheckCircle style={{ color: "red" }} />
              <p>Sculpture</p>
            </div>
          </div>
          <div className="grid-Item5">
            <div className="text">
              <FaRegCheckCircle style={{ color: "red" }} />
              <p>Pastels</p>
            </div>
            <div className="text">
              <FaRegCheckCircle style={{ color: "red" }} />
              <p>Realistic Color Pencil</p>
            </div>
            <div className="text">
              <FaRegCheckCircle style={{ color: "red" }} />
              <p>Hyperrealistic Art</p>
            </div>
            <div className="text">
              <FaRegCheckCircle style={{ color: "red" }} />
              <p>Charcoal</p>
            </div>
          </div>
        </div>
      </div>
      {/* ///////////////////////////////////////////////////////////////// */}
      <div className="containerMobile">
        <p>
          Soul Laxmi Arts Studio is a renowned institution that offers art
          classes for a diverse range of students, including children aged 4
          years to senior citizens aged 70 and above. The studio aims to develop
          art skills and conceptual thinking among budding artists, and provides
          a variety of classes, summer camps, and workshops on various art
          forms. The experienced faculty and the creative atmosphere of Soul
          Laxmi Arts Studio make it a wonderful place for all participants to
          explore different materials and mediums, which helps them refine their
          artistic abilities. Overall, Soul Laxmi Arts Studio is an excellent
          destination for anyone looking to nurture their creativity and
          artistic talent, regardless of their age or background.
        </p>

        <div>
          <img src={img0} alt="Laxmi" />
        </div>
        <div className="mobileCoursesContainer1">
          <div className="mobileCourses">
            <FaRegCheckCircle
              style={{ color: "red", margin: "0", padding: "0" }}
            />
            <p>Pencil Drawing and Sketching</p>
          </div>
          <div className="mobileCourses">
            <FaRegCheckCircle style={{ color: "red" }} />
            <p>Pencil colours</p>
          </div>
          <div className="mobileCourses">
            <FaRegCheckCircle style={{ color: "red" }} />
            <p>Soft pastels</p>
          </div>
          <div className="mobileCourses">
            <FaRegCheckCircle style={{ color: "red" }} />
            <p>Oil pastels</p>
          </div>
        </div>
        {/* /////////////////////////////// */}
        <div className="mobileCoursesContainer2">
          <div className="mobileCourses">
            <FaRegCheckCircle style={{ color: "red" }} />
            <p>Acrylic colours</p>
          </div>
          <div className="mobileCourses">
            <FaRegCheckCircle style={{ color: "red" }} />
            <p>Water colours</p>
          </div>
          <div className="mobileCourses">
            <FaRegCheckCircle style={{ color: "red" }} />
            <p>Clay modelling</p>
          </div>
          <div className="mobileCourses">
            <FaRegCheckCircle style={{ color: "red" }} />
            <p>Sculpture</p>
          </div>
        </div>
        <div className="mobileCoursesContainer3"></div>
        <div className="mobileCourses">
          <FaRegCheckCircle style={{ color: "red" }} />
          <p>Pastels</p>
        </div>
        <div className="mobileCourses">
          <FaRegCheckCircle style={{ color: "red" }} />
          <p>Realistic Color Pencil</p>
        </div>
        <div className="mobileCourses">
          <FaRegCheckCircle style={{ color: "red" }} />
          <p>Hyperrealistic Art</p>
        </div>
        <div className="mobileCourses">
          <FaRegCheckCircle style={{ color: "red" }} />
          <p>Charcoal</p>
        </div>
      </div>

      {/* ////////////////////////////////////////////////////// */}
      <div className="gridContainer">
        <div className="gridItemContainer">
          <img src={img1} alt="Laxmi" />
          <h2>Beginner</h2>
          {/* <p>(2 hours)</p> */}
          <p>Medium Poster color</p>
        </div>
        <div className="gridItemContainer">
          <img src={img2} alt="Laxmi" />
          <h2>Beginner</h2>
          {/* <p>(2 hours)</p> */}
          <p>Medium Oil Pastel</p>
        </div>
        <div className="gridItemContainer">
          <img src={img3} alt="Laxmi" />
          <h2>Advanced</h2>
          {/* <p>(2 hours)</p> */}
          <p>Medium Water Color</p>
        </div>
        <div className="gridItemContainer">
          <img src={img4} alt="Laxmi" />
          <h2>Beginner</h2>
          {/* <p>(2 hours)</p> */}
          <p>Pencil Sketch</p>
        </div>
        <div className="gridItemContainer">
          <img src={img5} alt="Laxmi" />
          <h2>Advanced</h2>
          <p>Pencil Sketch</p>
          {/* <p>
            Mediums ranging from pencils, pens, charcoal, soft pastels, water
            colours, acrylic colours, etc.
          </p> */}
        </div>
        <div className="gridItemContainer">
          <img src={img6} alt="Laxmi" />
          <h2>Beginner</h2>
          <p>Oil Painting,Mixing & Blending</p>
          {/* <p>
            Basic to advance level
            <br /> 4 Artworks
            <br /> 3-A4 and 1-A3
          </p> */}
        </div>
        <div className="gridItemContainer">
          <img src={img7} alt="Laxmi" />
          <h2>Seniors</h2>
          <p>Oil Painting</p>
          {/* <p>
            Mediums ranging from pencils, pens, charcoal, soft pastels, water
            colours, acrylic colours, etc.
          </p> */}
        </div>
        <div className="gridItemContainer">
          <img src={img8} alt="Laxmi" />
          <h2>Seniors</h2>
          <p>Oil Painting</p>
          {/* <p>
            Mediums ranging from pencils, pens, charcoal, soft pastels, water
            colours, acrylic colours, etc.
          </p> */}
        </div>
        <div className="gridItemContainer">
          <a href=" https://youtu.be/Tih2dhU_ZR0" target="_blank">
            <img src={img9} alt="Laxmi" style={{ height: "30vh" }} />
          </a>
          <h2>Video Clip</h2>
          <p>Artists</p>
          <p>
            Young talent's artwork showcased in workshop video: vibrant,
            creative, and inspiring display of artistic expression.
          </p>
        </div>
      </div>
    </>
  );
}
export default Courses;
