import React from "react";
// import { FaRegEnvelope } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import "./Mailicon.css";

function Mailicon() {
  return (
    <>
      <div className="mailContainer">
        <a
          href="mailto:soullaxmiartstudio@gmail.com"
          aria-label="Write mail to Soul Laxmi Arts Studio"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaEnvelope />
          soullaxmiartstudio@gmail.com
        </a>
      </div>
    </>
  );
}
export default Mailicon;
