import React from "react";
import { FaInstagram } from "react-icons/fa";
import "./Instagramicon.css";
function Instagramicon() {
  return (
    <div className="instagramIcon">
      <a
        href="https://www.instagram.com/artsysoullaxmi/?igshid=YmMyMTA2M2Y%3D"
        aria-label="Go to Instagram page of Soul Laxmi Arts Studio"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram />
      </a>
    </div>
  );
}
export default Instagramicon;
