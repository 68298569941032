import React from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
//npm install react-read-more-read-less --save have installed
import "./Gallery1.css";
import imgG1 from "./images/Laxmi2673G1.jpg";
import imgG2 from "./images/Laxmi2675G2.jpg";
import imgG3 from "./images/Laxmi2676G3.jpg";
// import imgG4 from "./images/Laxmi2677G4.jpg";
import imgG4 from "./images/imgG4a.jpeg";
import imgG5 from "./images/Laxmi2678G5.jpg";

import imgG6 from "./images/Laxmi2079G6.jpg";
import imgG7 from "./images/LaxmiG7.jpg";
import imgG8 from "./images/LaxmiG8.jpg";
import imgG9 from "./images/LaxmiG9.jpg";
import imgG10 from "./images/LaxmiG10.jpg";
import imgG11 from "./images/LaxmiG11.jpg";
import imgG12 from "./images/LaxmiG12.jpeg";
import imgG13 from "./images/LaxmiG13.jpeg";
import imgG14 from "./images/LaxmiG14.jpeg";
import imgG15 from "./images/LaxmiG15.jpeg";
import imgG16 from "./images/LaxmiG16.jpeg";
import imgG17 from "./images/LaxmiG17.jpeg";
import imgG18 from "./images/LaxmiG18.jpeg";
import imgG19 from "./images/LaxmiG19.jpeg";
import imgG20 from "./images/LaxmiG20.jpeg";
import imgG21 from "./images/LaxmiG21.jpeg";
import imgG22 from "./images/LaxmiG22.jpeg";
// import imgG23 from "./images/LaxmiG23.jpeg";
import img26b from "./images/img26b.jpg";
// import imgG24 from "./images/LaxmiG24.jpeg";
import imgG24b from "./images/img24b.jpg";
import imgG25 from "./images/LaxmiG25.jpeg";
import Blinking from "./Blinking";

function Gallery1() {
  return (
    <>
      <Blinking text={["Exhibitions coming up", "Request for price"]} />

      <div className="text">
        <h3>Art Gallery</h3>
      </div>
      <div className="gridcontainer">
        <div className="gridItem">
          {/* /////img1//////// */}
          <img src={imgG1} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
              style={{ color: "#007bff" }}
            >
              The close-knit family of lions becomes the prime focus of artist
              Laxmi Agarwal’s work, as she captures the unique, primal yet
              sensitive bond between animals and their families. The work exudes
              charm and emotion.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* //////////img2///////// */}
          <img src={imgG4} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              This work by Laxmi Agarwal creates a meditative ambience around
              the figure of the Buddha face. The work captures the gentleness
              and the peace aptly and conveys it to the viewer in subdued hues.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* //////////img3////////// */}
          <img src={imgG5} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              Artist Laxmi Agarwal creates a photorealistic work with a woman as
              her muse. Here she sits on the sunny beach while the viewer is
              anchored to the sharp glint of sun reflected in her eye. A
              perspectively nuanced work.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img4///////// */}
          <img src={imgG8} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              The freckled visage of a young vibrant girl fills up the canvas of
              Laxmi Agarwal who tries to capture the very life force of this
              model. There is an indisputable energy of youthfulness in this
              work.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* //////////img5/////////// */}
          <img src={imgG3} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              The work by Laxmi Agarwal brings to the viewer the absolute
              holiness of the Shivalingam, and her devotion towards this deity.
              The artist paints the work in monochromatic hues of the colour
              red- orange with the aim to capture the deep essence and power of
              the Shiva and Shakti and the primal quality they exude. The
              hibiscus flower accentuates the composition even more as the work
              vibrates with an unspoken energy.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* //////////img6///////// */}
          <img src={imgG10} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              The bond between a lion and his cub is a testament to the
              unmatched love of a father for his child. The lion protects and
              teaches his cub, ensuring its survival and growth, reflecting the
              unbreakable bond that exists between a father and his son.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////////img7////////// */}
          <img src={imgG7} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              The tree of life takes centre stage in this work by artist Laxmi
              Agarwal.The artist intends to bring to mind the concept of
              rootedness as compared to the spread of reaching for the sky.This
              work talks about the precarious ratio between effort and success
              as portrayed by the Tree of life.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ///////////img8///////// */}
          <img src={imgG2} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              The work by Laxmi Agarwal captures the masculinity of the bull
              figure,which has been a muse for generations of artists.The work
              is vibrantly painted in an attempt to fully elucidate the
              mobility,power and earthy stature of the bull figure.
              {/* The luxurious mane of an equine muse captures the astute attention
            of artist Laxmi Agarwal as she paints one of the most beloved muses
            (horse) of artists before her in time. This work emits a confidence
            and power which is intrinsic to the equine muse and is captured well
            by the artist. */}
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* //////////img9//////////// */}
          <img src={imgG9} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              Any home can be a castle if the king and queen are in love.The
              power couple comes into focus in this work by artist Laxmi Agarwal
              as the lion and the lioness cuddle. Human beings and animals have
              similar modes of display of affection; this only anchors the
              similarities and oneness of all living beings. The work reiterates
              this connection.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* /////////img10/////// */}
          <img src={imgG6} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              The bridge across forever, is what Laxmi Agarwal paints in this
              work. The bridge is a metaphor for change and compromise, a
              movement towards better things having resolved past indiscretions.
              The artist also portrays the light streaming through illuminating
              the way.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img11///////// */}
          <img src={imgG11} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              The work by Laxmi Agarwal brings to the viewer the essence of the
              river banks of the holy river Ganga, near the Benaras ghats. The
              artist creates the illusion of a golden sunrise on the sacred
              ghats where boats get ready to traverse the waters. The
              silhouettes of the temples standout against the golden hue of the
              sun rays just before the sunrise, it would seem.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img12///////// */}
          <img src={imgG12} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              The painting of Lion and cub depicts the ultimate bond between a
              father and son. The majestic lion stands tall, exuding strength
              and power, while his little cub looks up to him with admiration in
              his eyes. You can almost feel the emotions pouring out of this
              piece as it captures the tenderness and love that exists between
              these two creatures. The father lion's protective instincts are
              evident as he watches over his young one, teaching him valuable
              lessons about survival in their wild habitat. The cub looks up to
              his dad as an example to follow, knowing that he too will one day
              become just as strong and mighty. This painting is a beautiful
              representation of how important fathers are in shaping the lives
              of their children - guiding them through life's challenges with
              unwavering support and unconditional love.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img13///////// */}
          <img src={imgG13} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              The painting highlights the age-old method of cooking and serving
              food in clay pots, producing an unforgettable taste that evokes
              nostalgia when relived. The traditional approach accentuates the
              essence of the past, enabling us to relish the pure taste of
              home-cooked meals. Laxmi through her painted condemns the use of
              modern plastic utensils that harm both the environment and our
              bodies.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img14///////// */}
          <img src={imgG14} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              The blindfolded woman in Laxmi's painting represents how women can
              overcome limitations. Her closed eyes symbolize her ability to
              perceive and comprehend her surroundings without relying on visual
              cues. She holds the power to keep her thoughts and feelings to
              herself, but can also express and act upon them when needed.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img15///////// */}
          <img src={imgG15} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              As I stand here gazing at the painting of a steam engine emitting
              smoke and a whistle, my mind is transported to an era long gone.
              The nostalgic feeling that engulfs me is indescribable as I
              imagine myself standing on the platform, waiting for the train to
              arrive with its reassuring chukchuk sound. It's hard not to feel
              like we've lost something in today's fast-paced world where time
              seems to slip away from us too quickly. Laxmi's painting reminds
              us of simpler times when life seemed to stand still, allowing us
              to appreciate every moment without being constantly bombarded by
              notifications and distractions. Looking at this beautiful piece of
              art makes me yearn for those days when people took their time and
              savored every sip of life instead of rushing through it like they
              do now. It's amazing how much can be conveyed through one painting
              – an entire way of life captured in brushstrokes and colors!
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img16///////// */}
          <img src={imgG16} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              {/* Laxmi's painting of a woman surrounded by butterflies symbolizes
            feminine happiness and joy. The lady depicted exudes bliss while
            enjoying her own company, and the presence of butterflies signifies
            her freedom and lightness. Laxmi's intention is to convey the
            message that women can find happiness within themselves. */}
              Laxmi's painting featuring a woman encircled by butterflies
              symbolizes feminine contentment and elation. The lady portrayed
              emanates pure bliss as she relishes her own company, while the
              butterflies represent her sense of liberation and buoyancy.
              Laxmi's objective is to convey the idea that women can discover
              joy within themselves.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img17///////// */}
          <img src={imgG17} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              Women possess a remarkable ability to transform themselves and
              effortlessly fit into any relationship or circumstance with grace
              and confidence. This unique quality allows them to adapt and excel
              in diverse roles and responsibilities. Laxmi aims to depict the
              pride and ease with which women navigate through life's challenges
              while adjusting and accommodating various situations.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img18///////// */}
          <img src={imgG18} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              Have you ever heard the saying "white horse in mud remain white"?
              It's a sign of purity, innocence, calmness and modesty. Laxmi
              paints this beautiful white horse strolling through a muddy field
              without a care in the world. Its pristine coat remains untouched
              by the dirt and grime surrounding it. This sight is not only
              breathtakingly stunning but also symbolic of something greater
              than just its appearance. The immaculate state of this creature
              represents all that is virtuous and pure in this world; its
              innocence untainted by the harsh realities that surround us every
              day. And yet, despite being surrounded by an environment so
              contradictory to its own essence, this majestic animal exudes a
              sense of calmness that puts even the most anxious souls at ease.
              It's as if nothing can shake its confidence or disturb its inner
              peace, making us wonder what secrets lie behind those wise eyes.
              Perhaps we can learn from this symbol of uncorrupted beauty about
              how to maintain our own sense of serenity amidst chaos - after
              all, who wouldn't want to embody such gracefulness?
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img19///////// */}
          <img src={imgG19} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              Laxmi portrays feminine as a canvas, with many colors and emotions
              waiting to be discovered. Women carry all of their emotions on
              their sleeves, ready to paint the world with their vibrant hues.
              Black represents the pain that they have endured throughout
              history but it also shows the strength that lies beneath it all.
              From joyous reds to peaceful blues, women express themselves in
              different colors depending on what emotion they want to convey.
              Whether it's anger or love, happiness or sadness - women can show
              the full spectrum of feelings through color alone. Women are truly
              a masterpiece waiting to be admired by anyone who takes the time
              to see them for who they truly are; creative and emotional beings
              bursting with life and energy!
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img20///////// */}
          <img src={imgG20} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              Laxmi, a passionate painter with a love for horses, has chosen to
              immortalize the Akhal-Teke on her canvas. This breed, with a
              history that dates back to 3000 BC, is known for its remarkable
              speed, endurance, and a unique metallic sheen that shimmers like
              gold. Unfortunately, the Akhal-Teke is an endangered species, and
              Laxmi's painting captures its beauty and raises awareness of the
              need to preserve this magnificent animal for future generations.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img21///////// */}
          <img src={imgG21} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              Laxmi Paints a women who feel an urge to get away from it all and
              just be by herself for a little while. So what do you do? Well, if
              you're anything like me and many other women out there, you find
              yourself dipping your legs in super cool ice water - feeling that
              icy coldness seep into your bones and wash away any stress or
              worries. Then maybe you find yourself wandering along a riverbank
              until you come across a quiet spot where nobody can bother you -
              sitting on a rock with nothing but the sound of the water rushing
              by as company. And finally, when it feels like enough time has
              passed and every inch of your body is at peace, perhaps you end up
              spending quality time with yourself; indulging in hobbies or
              activities that bring joy to your soul without anyone else's
              opinion or input clouding your mind. Yes indeed, at times women
              want nothing more than to escape the noise and chaos of everyday
              life and simply spend some much-needed alone time and take on
              whatever comes next!
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img22///////// */}
          <img src={imgG22} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              Laxmi captures in her painting the "After a rainy day moment",
              there's nothing quite like feeling the coolness of the damp earth
              beneath your bare feet as you step outside to take in the fresh
              air. You can't help but breathe deeply, savoring that unmistakable
              scent of rain mixed with freshly turned soil. And as you stroll
              along, admiring how nature seems to come alive after a good storm,
              your nose is suddenly tickled by another delicious aroma - hot
              corn! It's steaming away on the grill nearby and looks perfectly
              golden brown and buttery. Without hesitation, you make a beeline
              for it and grab an ear, enjoying its sweetness while still basking
              in the aftermath of Mother Nature's shower. The contrast of
              flavors between the warm corn and cool surroundings makes each
              bite all that more delicious as you relish this perfect moment;
              smelling mud never tasted so good!
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img23///////// */}
          <img src={img26b} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              Laxmi's painting depicts a horse in a carefree and jubilant state,
              exuding a sense of joy and freedom that humans experience when
              unencumbered by life's stresses. Through the horse's playful
              movements and expression, the painting encourages us to embrace
              life, let go of our worries, and find happiness in the present
              moment. It serves as a reminder to appreciate the simple pleasures
              in life and bask in the joy they bring.
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img24///////// */}
          <img src={imgG24b} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              Laxmi's painting of Buddha is a masterpiece that captures the
              essence of emotions we act upon situations created soul. Her brush
              strokes are so exquisite that they bring to life every detail in
              the portrait. The intricacy and attention paid to each line,
              curve, and color create an almost tangible atmosphere within the
              painting. The serene expression on Buddha's face exudes peace and
              tranquility while simultaneously conveying wisdom beyond measure.
              Looking at Laxmi's piece, one cannot help but feel drawn into its
              ethereal quality as if being transported to another realm
              altogether. It encapsulates all aspects of human emotion - from
              joy to sorrow, love to hate- in such a way that it seems like they
              were meant to be expressed through art alone! This work truly
              showcases Laxmi's talent as an artist who can evoke deep feelings
              within us just by looking at her paintings – what a gift she has!
            </ReactReadMoreReadLess>
          </p>
        </div>
        <div className="gridItem">
          {/* ////////img25///////// */}
          <img src={imgG25} alt="Laxmi" />
          <p>
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={175}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
            >
              Laxmi's painting beautifully captures the profound connection
              between femininity and Mother Earth. Through her artistry, she
              portrays a woman as the embodiment of life-giving forces, just
              like the Earth itself. The painting symbolizes the nurturing and
              caring qualities that both women and the Earth possess. Laxmi's
              brushstrokes depict the woman as a replica of the Earth,
              emphasizing their intertwined existence. It is a testament to the
              love and tenderness that women possess, mirroring the Earth's
              capacity to sustain and nurture life. This artwork serves as a
              powerful reminder of the inherent connection between women, Mother
              Earth, and the vital role they play in our world.
            </ReactReadMoreReadLess>
          </p>
        </div>
      </div>
    </>
  );
}
export default Gallery1;
