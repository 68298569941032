import React from "react";
import { FaFacebookF } from "react-icons/fa";

import "./Facebookicon.css";
function Facebookicon() {
  return (
    <div className="facebookIcon">
      <a
        href="https://www.facebook.com/people/Laxmi-Arts/100076413091208/?paipv=0&eav=AfZNuvRbj4S2kepp1rCIygblrTFNw"
        aria-label="Go to Facebook page of Soul Laxmi Arts Studio"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebookF />
      </a>
    </div>
  );
}
export default Facebookicon;
