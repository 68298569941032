import React from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Karousel from "./Karousel";
import img3 from "./images/Laxmi3.jpg";
import img4 from "./images/Laxmi10.jpg";
import imgE11 from "./images/LaxmiE11.jpg";
import imgE13 from "./images/LaxmiE13.jpg";
import imgE19 from "./images/LaxmiE19.jpg";
import imgE17 from "./images/LaxmiE17.jpg";
import imgC14 from "./images/LaxmiC14.jpg";
import imgC15 from "./images/LaxmiC15.jpg";
import imgC16 from "./images/LaxmiC16.jpg";
import Blinking from "./Blinking";
import "./Home.css";
function Home() {
  return (
    <>
      <div className="imageHead">
        <img src={img3} alt="Laxmi" />
      </div>
      <div>
        <Blinking
          text={[
            "Grand opening of Studio on 10th march",

            "Enroll in Summer Camp from 15th to 28th April",
            " Summer Camp offers a unique opportunity for kids to explore",
            "three types of medium Oil pastel,Poster color,Pottery paintings",
            // "Laxmi, the aesthetic artist, creates beautiful paintings that inspire and captivate.",
            "Contact us. ",
            "Summer Camp starting from 15th to 28th April Enroll now",
            "Contact us to Enroll.",
            // "Laxmi instructs and inspires artists through",
            // "painting ",
          ]}
        />
      </div>

      <div className="gridContainer1">
        <div className="gridContainer1img">
          <img src={img4} alt="Laxmi paintings " />
        </div>

        <div className="gridContainer1Text">
          <h2>About Soul Laxmi Arts Studio</h2>
          <p>
            {/* The vibrant and pulsating works of Laxmi Agarwal address the world
            we live in and simultaneously lead us on a journey within ourselves.
            The origins of life, soul and the indomitable spirits of nature,
            plants, animals and humans are deeply intertwined creating the
            wellspring of creativity, which inhabit the colourful canvases of
            Laxmi’s works. The artist tends to look at the bigger picture of all
            living and nonliving things and traces a unifying link through it
            all depicting Oneness. The unique result is a vivid tapestry of
            junctures where life and life forces intermingle and throb with
            synergy. Laxmi works on canvases and paper mediums, and in both
            these mediums, she manages to create depth, especially in the eyes
            of her human figures, harmony which the viewers can identify with.
            The photorealistic figuration, coupled with the near abstraction of
            quotidian imagery form the foundation of Laxmi’s work. */}
            <ReactReadMoreReadLess
              readMoreClassName="readMoreClassName"
              readLessClassName="readLessClassName"
              charLimit={800}
              readMoreText="Read More⬇️"
              readLessText="Read Less⬆️"
              // style={{ color: "#007bff" }}
            >
              Laxmi Agarwal's artwork is a testament to the interconnectedness
              of all things in our world. Through her use of vibrant colors and
              dynamic compositions, she invites us on a journey that both
              reflects the external world around us and leads us on a journey
              within ourselves. Her art explores the origins of life, soul, and
              the indomitable spirits of nature, plants, animals, and humans. By
              highlighting the interdependence of all living and non-living
              things, Laxmi's work creates a vivid tapestry of junctures where
              life and life forces intermingle and throb with synergy. Laxmi's
              artistic vision centers on the idea of Oneness, tracing a unifying
              link through all elements of the natural world. Through her
              paintings, she invites viewers to consider the deeper connections
              that exist between all things and to contemplate the impact of
              these connections on our lives. Her work reminds us that we are
              all part of the same interconnected ecosystem, and that we must
              take care of our planet and each other. Whether working on
              canvases or paper, Laxmi creates depth in her artwork,
              particularly in the eyes of her human figures. Her ability to
              capture human expression with photorealistic detail is striking,
              and this realism is juxtaposed with the near abstraction of
              quotidian imagery. This contrast creates a compelling visual
              experience that encourages viewers to question the boundaries
              between reality and abstraction. Overall, Laxmi Agarwal's artwork
              is a powerful celebration of the beauty and interconnectedness of
              our world. Through her use of color, composition, and figuration,
              she invites us to contemplate our place within the larger context
              of the natural world. Her work reminds us of the importance of
              embracing Oneness and recognizing the deep interdependence of all
              things. Laxmi's art is a call to action, urging us to care for our
              planet, each other, and ourselves.
            </ReactReadMoreReadLess>
          </p>
        </div>
      </div>
      <Karousel />
      {/* ////////////////////////////////////////////////// */}
      <div className="text">
        <h2>Events & Awards</h2>
      </div>
      <div className="gridContainer2">
        <div className="gridContainer2Items">
          <img src={imgE11} alt="Laxmi" />
          <p>
            Laxmi with well renowed architect
            <br /> PREM NATH Sir! .
          </p>
        </div>
        <div className="gridContainer2Items">
          <img src={imgE13} alt="Laxmi" />
          <p>A short glimpse of my first Art workshop & Exhibition</p>
        </div>
        <div className="gridContainer2Items">
          <img src={imgE19} alt="Laxmi" />
          <p>Art Exhibition at Heart Cup Coffee-Kondapur .</p>
        </div>
        <div className="gridContainer2Items">
          <img src={imgE17} alt="Laxmi" />
          <p>
            Buddha inspires Laxmi's journey <br />
            as an artist.Creativity,
            <br />
            spirituality intertwined!
          </p>
        </div>
        {/* <div className="gridContainer2Items">
          <img src={imgE17} alt="Laxmi" />
          <p>
            Buddha inspires Laxmi's journey <br />
            as an artist.Creativity,
            <br />
            spirituality intertwined!
          </p>
        </div> */}
      </div>

      {/* /////Grid client container////////////////////// */}
      <div className="text">
        <h2>Our Clients</h2>
      </div>
      <div className="gridContainer3Client">
        <div className="gridContainer3ClientItems">
          <img src={imgC14} alt="Laxmi" />
          <p>
            Laxmi with PV SINDHU, inspiring Buddha portrait uniting through art
            and inspiration. Joyful moment!
          </p>
        </div>

        <div className="gridContainer3ClientItems">
          <img src={imgC15} alt="Laxmi" />
          <p>
            Honored to have my painting requested by
            <br /> Dr. SRINIVAS ILA, AIG Hospital. Grateful!
          </p>
        </div>

        <div className="gridContainer3ClientItems">
          <img src={imgC16} alt="Laxmi" />
          <p>
            Ecstatic that my painting adorns <br />
            SAINA and PARUPALLI KASHYAP's home.
          </p>

          {/* Laxmi with PV Sindhu, inspiring Buddha portrait  , uniting through art and inspiration. Joyful moment! */}
        </div>
      </div>
    </>
  );
}
export default Home;
